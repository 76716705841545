<template>
  <div class="row justify-content-center align-items-md-center">
    <div class="col-8">
      <div class="card full-height bg-ligth">
        <div class="card-header">
          <h3 class="mb-0">Empresas</h3>
        </div>
        <div class="card-body">
          <b-table
          :striped="false"
            :small="true"
            :hover="true"
            :fields="['nome', 'status', 'acoes']"
            :items="lista"
            :per-page="perPage"
            :current-page="currentPage"
            id="id-table"
            show-empty
            empty-text="Nenhum registro encontrado!"
          >
            <template #head(acoes)><span></span></template>
            <template #cell(acoes)="{ item }">
              <div class="text-right w-100">
                <button
                  @click="confirm(item)"
                  class="btn btn-icon btn-light btn-sm mx-1"
                  v-b-tooltip.hover
                  title="Bloquear/Desbloquear"
                >
                  <i class="fas fa-unlock-alt text-danger"></i>
                </button>
              </div>
            </template>
            
          </b-table>
          <b-pagination
            v-model="currentPage"
            :total-rows="lista.length"
            :per-page="perPage"
            aria-controls="grade_curricular-table"
          >
          </b-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/store/breadcrumbs.module";

export default {
  data() {
    return {
      currentPage: 1,
      perPage: 50,
    };
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Departamentos" }]);
  },
  computed: {
    lista() {
      return this.$store.state.departamentos.lista;
    },
  },
  created() {
    this.Listar();
  },
  methods: {
    async Listar() {
      this.$store.dispatch("MudarPreloader", true);
      await this.$store.dispatch("departamentos/all").finally(() => {
        this.$store.dispatch("MudarPreloader", false);
        this.$store.dispatch("atualizar", "");
      });
    },

    atualizar(value) {
      this.$router.push({ name: "createGradeCurricular" });
      this.$store.dispatch("atualizar", value);
    },
  },
};
</script>

<style scoped>
.lista:hover {
  background-color: rgba(167, 183, 255, 0.705);
}
</style>